import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getFirestore, doc, getDoc, updateDoc, addDoc, collection, query, orderBy, limit, getDocs, deleteDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import Firebase Auth
import "../styles/EditArticlePage.css";
import { useTranslation } from "react-i18next"; // For internationalization

const EditArticlePage = () => {
  const { id } = useParams(); // Get article ID from URL (if editing)
  const { t } = useTranslation(); // Initialize translation function
  const [title, setTitle] = useState(""); // Title of the article
  const [content, setContent] = useState(""); // Rich text editor content
  const [category, setCategory] = useState("Tech"); // Category of the article
  const [language, setLanguage] = useState("English"); // Language of the article
  const [articleId, setArticleId] = useState(null); // Incremental ID
  const [articleImage, setArticleImage] = useState(""); // Base64 image data
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // State to track delete confirmation

  const [loading, setLoading] = useState(!!id); // Only load if editing
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const quillRef = useRef(null); // Reference to Quill editor instance
  const navigate = useNavigate(); // To redirect after creating a new article

  // Fetch the current user from Firebase Auth
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // Function to get the next incremental article ID
  const fetchNextArticleId = async () => {
    const articlesQuery = query(collection(getFirestore(), "news"), orderBy("id", "desc"), limit(1));
    const articlesSnapshot = await getDocs(articlesQuery);

    if (articlesSnapshot.docs.length > 0) {
      const lastArticle = articlesSnapshot.docs[0].data();
      return lastArticle.id + 1; // Increment the last article ID by 1
    } else {
      return 1; // If no articles, start from 1
    }
  };

  useEffect(() => {
    if (id) {
      const fetchArticle = async () => {
        try {
          const articleDoc = doc(getFirestore(), "news", id);
          const articleSnapshot = await getDoc(articleDoc);

          if (articleSnapshot.exists()) {
            const articleData = articleSnapshot.data();
            setTitle(articleData.title || "");
            setContent(articleData.content || "");
            setCategory(articleData.category || "Tech");
            setLanguage(articleData.language || "English");
            setArticleId(articleData.id || null);
            setLoading(false);
          } else {
            setError(t("articleEditPage.error.articleNotFound"));
            setLoading(false);
          }
        } catch (err) {
          setError(t("articleEditPage.error.fetchError"));
          setLoading(false);
        }
      };

      fetchArticle();
    } else {
      // Fetch the next available article ID for new articles
      fetchNextArticleId().then(setArticleId);
    }
  }, [id, t]);

  const handleContentChange = useCallback((value) => {
    setContent(value);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      setError(t("articleEditPage.error.notLoggedIn"));
      return;
    }

    try {
      if (id) {
        // If an ID exists, update the existing article
        const articleDoc = doc(getFirestore(), "news", id);
        await updateDoc(articleDoc, {
          title,
          content,
          category,
          language,
          id: articleId,
          image: articleImage // Save the Base64 image to Firestore

        });
        setSuccess(true);
      } else {
        // Otherwise, create a new article
        await addDoc(collection(getFirestore(), "news"), {
          title,
          content,
          category,
          language,
          image: articleImage, // Save the Base64 image to Firestore
          id: articleId, // Save the incremental article ID
          date: new Date().toLocaleDateString(),
          authorId: currentUser.uid // Save the current user's ID as the author
        });
        setSuccess(true);
        navigate("/my-articles"); // Redirect to the user's articles page after creation
      }
    } catch (err) {
      setError(t("articleEditPage.error.updateError"));
    }
  };

  const quillModules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"]
      ]
    }
  }), []); // Ensure this object is memoized so it doesn't cause re-renders

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setArticleImage(reader.result); // Store the Base64 image in articleImage state
      };
      reader.readAsDataURL(file); // Convert the image to Base64
    }
  };

  // Handle article deletion
  const handleDelete = async () => {
    if (!currentUser) {
      setError(t("articleEditPage.error.notLoggedIn"));
      return;
    }

    try {
      const articleDoc = doc(getFirestore(), "news", id);
      console.log(articleDoc)
      await deleteDoc(articleDoc); // Delete the article
      setSuccess(true);
      navigate("/my-articles"); // Redirect after deletion
    } catch (err) {
      console.log(err)
      setError(t("articleEditPage.error.deleteError"));
    }
  };

  // Function to show the delete confirmation dialog
  const confirmDelete = () => {
    const confirmed = window.confirm(t("articleEditPage.deleteConfirmation"));
    if (confirmed) {
      handleDelete();
    }
  };


  if (loading) {
    return <div>{t("articleEditPage.loading")}</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="article-edit-container">
      <h2>{id ? t("articleEditPage.titleEdit") : t("articleEditPage.titleCreate")}</h2>
      {success && <p className="success-message">{t("articleEditPage.successMessage")}</p>}
      <form onSubmit={handleSubmit} className="article-edit-form">
        <div>
          <label htmlFor="title">{t("articleEditPage.form.titleLabel")}</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="content">{t("articleEditPage.form.contentLabel")}</label>
          <div className="editor-container">
            {/* Quill Editor */}
            <ReactQuill
              ref={quillRef} // Attach ref to access the editor instance
              value={content}
              onChange={handleContentChange}
              theme="snow"
              modules={quillModules} // Memoized Quill modules
            />
          </div>
        </div>

        {/* Category Field */}
        <div>
          <label htmlFor="category">{t("articleEditPage.form.categoryLabel")}</label>
          <select id="category" value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value={t("articleEditPage.categories.tech")}>{t("articleEditPage.categories.tech")}</option>
            <option value={t("articleEditPage.categories.sports")}>{t("articleEditPage.categories.sports")}</option>
            <option value={t("articleEditPage.categories.TDAH")}>{t("articleEditPage.categories.TDAH")}</option>
            <option value={t("articleEditPage.categories.adultTDAH")}>{t("articleEditPage.categories.adultTDAH")}</option>
            <option value={t("articleEditPage.categories.children")}>{t("articleEditPage.categories.children")}</option>
            <option value={t("articleEditPage.categories.flow")}>{t("articleEditPage.categories.flow")}</option>
            <option value={t("articleEditPage.categories.mentalHealth")}>{t("articleEditPage.categories.mentalHealth")}</option>
            <option value={t("articleEditPage.categories.health")}>{t("articleEditPage.categories.health")}</option>
            <option value={t("articleEditPage.categories.education")}>{t("articleEditPage.categories.education")}</option>
          </select>
        </div>

        {/* Language Field */}
        <div>
          <label htmlFor="language">{t("articleEditPage.form.languageLabel")}</label>
          <select id="language" value={language} onChange={(e) => setLanguage(e.target.value)}>
            <option value={t("articleEditPage.languages.english")}>{t("articleEditPage.languages.english")}</option>
            <option value={t("articleEditPage.languages.portuguese")}>{t("articleEditPage.languages.portuguese")}</option>
            <option value={t("articleEditPage.languages.spanish")}>{t("articleEditPage.languages.spanish")}</option>
          </select>
        </div>

        {/* Image Upload Field */}
        <div>
          <label htmlFor="imageUpload">{t("articleEditPage.form.imageLabel")}</label>
          <input type="file" id="imageUpload" accept="image/*" onChange={handleImageUpload} />
        </div>

        {/* Display the article ID */}
        {articleId && (
          <div className="article-id-display">
            <p>{t("articleEditPage.form.id")}: {articleId}</p>
          </div>
        )}

        <button type="submit" className="submit-button">
          {t("articleEditPage.form.submitButton")}
        </button>
      </form>

        {/* Delete button for pre-existing articles */}
        {id && (
          <div className="delete-article-container">
            <button className="delete-button" onClick={confirmDelete}>
              {t("articleEditPage.form.deleteButton")}
            </button>
          </div>
      )}
    </div>
  );
};

export default EditArticlePage;
