import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ProductGrid from "../components/ProductGrid";
import "../styles/ComparisonTable.css";
import HorizontalSlider from "../components/HorizontalSlider";
import { getUserLanguage } from "../utils";

import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Ensure this path is correct in your project

const CategoryPage = () => {
  const { categoryName } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const [products, setProducts] = useState(location.state?.products || []);
  const [categoryDescription, setCategoryDescription] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  const normalizedCategoryName = categoryName.toLowerCase().trim();

  useEffect(() => {
    const fetchProducts = async () => {
      const userLanguage = getUserLanguage();

      if (!products.length) {
        try {
          const productsQuery = query(
            collection(db, "products"),
            where("language", "==", userLanguage),
            where("categories", "array-contains", normalizedCategoryName)
          );
          const querySnapshot = await getDocs(productsQuery);
          const allProducts = [];
          querySnapshot.forEach((doc) => {
            allProducts.push({ id: doc.id, ...doc.data() });
          });
          setProducts(allProducts);
        } catch (error) {
          console.error("Error fetching products:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [products, normalizedCategoryName]);

  useEffect(() => {
    const fetchCategoryData = async () => {
      const userLanguage = getUserLanguage();

      try {
        const categoryQuery = query(
          collection(db, "categories"),
          where("language", "==", userLanguage),
          where("name", "==", normalizedCategoryName)
        );
        const categorySnapshot = await getDocs(categoryQuery);
        if (!categorySnapshot.empty) {
          const categoryDoc = categorySnapshot.docs[0];
          const categoryData = categoryDoc.data();
          setCategoryDescription(categoryData.description);
        }

        const newsQuery = query(
          collection(db, "news"),
          where("language", "==", userLanguage),
          where("productCategories", "array-contains", normalizedCategoryName)
        );
        const newsSnapshot = await getDocs(newsQuery);
        const related = [];
        newsSnapshot.forEach((doc) => {
          related.push({ id: doc.id, ...doc.data() });
        });
        setRelatedArticles(related);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };

    fetchCategoryData();
  }, [categoryName, products, normalizedCategoryName]);

  return (
    <div className="category-page">
      <Helmet>
        <title>{`${categoryName} | My Site`}</title>
        <meta name="description" content={categoryDescription || ""} />
      </Helmet>

      {relatedArticles.length > 0 && (
        <section className="related-articles-section">
          <h2>{t("categoryPage.relatedArticles")}</h2>
          <div className="related-articles-grid">
            {relatedArticles.map((relatedArticle) => (
              <div key={relatedArticle.id} className="related-article-card">
                <a href={`/news/${relatedArticle.id}`}>
                  <img
                    src={relatedArticle.image}
                    alt={relatedArticle.title}
                    className="related-article-image"
                  />
                  <h3>{relatedArticle.title}</h3>
                </a>
              </div>
            ))}
          </div>
        </section>
      )}
      <h1>
        {t("categoryPage.productsIn")} {categoryName}
      </h1>
      {loading ? (
        <div className="loading-spinner"></div>
      ) : products.length > 0 ? (
        <ProductGrid products={products} />
      ) : (
        <p>{t("categoryPage.noProductsFound")}</p>
      )}

      <h2>{t("categoryPage.productsComparison")}</h2>
      {categoryDescription && (
        <div
          className="category-description"
          dangerouslySetInnerHTML={{ __html: categoryDescription }}
        />
      )}
    </div>
  );
};

export default CategoryPage;
