import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeroBanner from "../components/HeroBanner";
import NewsBanner from "../components/NewsBanner";
import SubscriptionForm from "../components/SubscriptionForm";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore"; // Import Firestore methods
import { db } from "../firebase"; // Import Firebase config
import "../styles/HomePage.css";

const HomePage = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [categoryLinks, setCategoryLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [featuredArticles, setFeaturedArticles] = useState([]); // Placeholder for featured articles
  const navigate = useNavigate();

  // Fetch categories and articles from Firestore
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(db, "articles")); // Assuming 'articles' is the collection
        const categoryMap = {};

        categoriesSnapshot.forEach((doc) => {
          const article = doc.data();
          const { category } = article;
          if (!categoryMap[category]) {
            categoryMap[category] = [];
          }
          categoryMap[category].push(article);
        });

        setCategories(categoryMap);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchCategories(); // Fetch articles on component mount
  }, []);

  const getCategoryTitle = (category) => {
    const translationKey = `homepage.${category.toLowerCase()}Collection`;
    return t(translationKey, { defaultValue: category });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const results = [];
    const links = [];
    Object.keys(categories).forEach((category) => {
      const articlesInCategory = categories[category].filter((article) =>
        article.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (articlesInCategory.length > 0) {
        results.push(...articlesInCategory);
      }
      if (category.toLowerCase().includes(searchQuery.toLowerCase())) {
        links.push(category);
      }
    });
    setSearchResults(results);
    setCategoryLinks(links);
  };

  const handleCategoryClick = (category) => {
    const articles = categories[category];
    navigate(`/category/${category.toLowerCase()}`, {
      state: { articles },
    });
  };

  return (
    <div className="homepage">
      <section className="featured-articles">
        <h2>{t("homepage.featuredArticles")}</h2>
        {/* Replace with your actual component to display articles */}
        <div className="article-list">
          {featuredArticles.map((article, index) => (
            <div key={index} className="article-item">
              <h3>{article.title}</h3>
              <p>{article.summary}</p>
              <a rel="canonical" href={article.link}>
                {t("homepage.readMore")}
              </a>
            </div>
          ))}
        </div>
      </section>
      

      <NewsBanner /> {/* Display recent news */}
      <section className="subscription-section">
        <SubscriptionForm />
      </section>
      <section className="affiliate-disclaimer">
        <p>{t("affiliateDisclaimer.message")}</p>
      </section>
      {searchResults.length > 0 && (
        <section className="search-results">
          <h2>{t("homepage.searchResults")}</h2>
          <div className="article-grid">
            {searchResults.map((article, index) => (
              <div key={index} className="article-item">
                <h3>{article.title}</h3>
                <p>{article.summary}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      <HeroBanner /> {/* Enhanced HeroBanner with CTA */}
      {categoryLinks.length > 0 && (
        <section className="category-links">
          <h2>{t("homepage.relatedCategories")}</h2>
          <ul>
            {categoryLinks.map((category) => (
              <li key={category}>
                <button
                  className="category-link-button"
                  onClick={() => handleCategoryClick(category)}
                >
                  {getCategoryTitle(category)}
                </button>
              </li>
            ))}
          </ul>
        </section>
      )}
      {Object.keys(categories).map((category) => (
        <section className="category-section" key={category}>
          <h2>
            <button
              className="category-link-button"
              onClick={() => handleCategoryClick(category)}
            >
              {getCategoryTitle(category)}
            </button>
          </h2>
          <div className="article-grid">
            {categories[category].map((article, index) => (
              <div key={index} className="article-item">
                <h3>{article.title}</h3>
                <p>{article.summary}</p>
              </div>
            ))}
          </div>
          <button
            key={category}
            onClick={() => handleCategoryClick(category)}
            className="view-more"
          >
            {t("homepage.viewMore", { category: getCategoryTitle(category) })}
          </button>
        </section>
      ))}
    </div>
  );
};

export default HomePage;
