import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserLanguage } from '../utils';

import "../styles/NewsBanner.css";

// Import Firebase Firestore
import { db } from '../firebase'; // Adjust the path if necessary
import { collection, query, where, getDocs, limit } from "firebase/firestore";

const NewsBanner = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const userLanguage = getUserLanguage();
        const newsRef = collection(db, 'news');

        // Query to get the latest 5 news articles in the user's language
        console.log(userLanguage);
        const q = query(newsRef, where('language', '==', userLanguage), limit(5));

        const querySnapshot = await getDocs(q);
        const newsData = [];
        querySnapshot.forEach((doc) => {
          newsData.push({ id: doc.id, ...doc.data() });
        });

        setNews(newsData);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [news]);

  if (news.length === 0) {
    return <div>{t("newsBanner.loading")}</div>;
  }

  const currentNews = news[currentIndex];

  return (
    <section className="news-slider">
      <div
        className="slide"
        style={{ backgroundImage: `url(${currentNews.image})` }}
      >
        <div className="slide-content">
          <div
            dangerouslySetInnerHTML={{
              __html: currentNews.content.substring(0, 150) + "...",
            }}
          />
          <a
            rel="canonical"
            href={`/news/${currentNews.id}`}
            className="read-more"
          >
            {t("newsBanner.readMore")}
          </a>
        </div>
      </div>
      <div className="slider-controls">
        <button
          className="prev"
          onClick={() =>
            setCurrentIndex(
              (prevIndex) => (prevIndex - 1 + news.length) % news.length
            )
          }
        >
          &#10094;
        </button>
        <button
          className="next"
          onClick={() =>
            setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length)
          }
        >
          &#10095;
        </button>
      </div>
      <div className="slider-dots">
        {news.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default NewsBanner;
