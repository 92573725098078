import React, { useState, useEffect, createContext, useContext } from "react";
import { auth } from "../firebase"; // Firebase Auth
import { onAuthStateChanged, signOut } from "firebase/auth"; // Firebase Auth listener and signOut function
import { getDoc, doc } from "firebase/firestore"; // Firestore functions
import { db } from "../firebase"; // Firestore config

// Create a Context for Auth
const AuthContext = createContext(null);

// Custom hook to easily access the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component to wrap the app and manage authentication state
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Monitor the auth state using Firebase's onAuthStateChanged
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // User is logged in, fetch the user data from Firestore
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            setCurrentUser({ uid: user.uid, ...userDoc.data() });
          } else {
            console.error("User document not found in Firestore.");
            setCurrentUser(null);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setCurrentUser(null); // Ensure currentUser is set to null on error
        }
      } else {
        // No user is logged in
        setCurrentUser(null);
      }
      setLoading(false);
    });

    // Clean up the listener when the component unmounts
    return unsubscribe;
  }, []);

  // Function to log out the user
  const logoutUser = async () => {
    try {
      await signOut(auth); // Sign out the user using Firebase auth
      setCurrentUser(null); // Clear currentUser from the context
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // Loading state while checking auth
  if (loading) {
    return <div>Loading...</div>;
  }

  // Provide the current user and logout function to all children components
  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, logoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};
