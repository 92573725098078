import React, { useEffect, useState } from "react";
import { useParams,  Link  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import HorizontalSlider from "../components/HorizontalSlider";
import SubscriptionForm from "../components/SubscriptionForm";
import "../styles/NewsArticlePage.css";
import { getUserLanguage } from "../utils";

import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Ensure this path is correct in your project

const stripHtmlTags = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || "";
};

const NewsArticlePage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [article, setArticle] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [error, setError] = useState(null);

  const userIsAdmin = true; // Placeholder: Replace with your own admin check logic


  useEffect(() => {
    const fetchArticleData = async () => {
      const userLanguage = getUserLanguage();

      try {
        // Convert id to the appropriate type
        const articleId = isNaN(id) ? id : parseInt(id, 10);

        // Query for the article where the 'id' field matches
        const articleQuery = query(
          collection(db, "news"),
          where("language", "==", userLanguage),
          where("id", "==", articleId)
        );
        const articleSnapshot = await getDocs(articleQuery);

        if (!articleSnapshot.empty) {
          const doc = articleSnapshot.docs[0];
          const articleData = doc.data();
          setArticle({ docId: doc.id, refId: doc.ref.id, ...articleData });
          console.log("Document Snapshot");
          console.log(doc.ref.id)

          if (
            articleData.productCategories &&
            articleData.productCategories.length > 0
          ) {
            // Fetch related products
            const productsQuery = query(
              collection(db, "products"),
              where("language", "==", userLanguage),
              where(
                "categories",
                "array-contains-any",
                articleData.productCategories
              )
            );
            const productsSnapshot = await getDocs(productsQuery);
            const products = [];
            productsSnapshot.forEach((doc) => {
              products.push({ id: doc.id, ...doc.data() });
            });
            setRelatedProducts(products);

            // Fetch related articles
            const relatedArticlesQuery = query(
              collection(db, "news"),
              where("language", "==", userLanguage),
              where(
                "productCategories",
                "array-contains-any",
                articleData.productCategories
              )
            );
            const relatedArticlesSnapshot = await getDocs(relatedArticlesQuery);
            const related = [];
            relatedArticlesSnapshot.forEach((doc) => {
              const data = doc.data();
              if (data.id !== articleData.id) {
                related.push({ id: data.id, ...data });
              }
            });
            setRelatedArticles(related);
          }
        } else {
          console.error("Article not found");
          setError(t("newsArticlePage.articleNotFound"));
        }
      } catch (error) {
        console.error("Error fetching article data:", error);
        setError(t("newsArticlePage.errorFetchingArticle"));
      }
    };

    fetchArticleData();
  }, [id, t]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!article) {
    return <div>{t("newsArticlePage.loading")}</div>;
  }

  const plainTextContent = stripHtmlTags(article.content);

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: article.title,
    image: article.image,
    datePublished: article.date,
    author: {
      "@type": "Person",
      name: article.author || "Unknown",
    },
    keywords: article.keywords ? article.keywords.join(", ") : "",
    articleBody: plainTextContent,
  };

  return (
    <div className="news-article-page">
      <Helmet>
        <title>{article.title}</title>
        <meta
          name="description"
          content={plainTextContent.substring(0, 150)}
        />
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>

      <h1>{article.title}</h1>
      <div className="news-article-meta">
        <p className="news-article-date">
          {new Date(article.date).toLocaleDateString()}
        </p>
        {article.author && (
          <p className="news-article-author">
            {t("newsArticlePage.author")}: {article.author}
          </p>
        )}
      </div>
      <img
        src={article.image}
        alt={article.title}
        className="news-article-image"
      />
      <div dangerouslySetInnerHTML={{ __html: article.content }} />
      {userIsAdmin && (
        <div className="edit-button-container">
          <Link to={`/articles/edit/${article.refId}`} className="edit-button">
            {t("newsArticlePage.editArticle")}
          </Link>
        </div>
      )}

      {relatedProducts.length > 0 && (
        <section className="related-products-section">
          <h2>{t("newsArticlePage.relatedProducts")}</h2>
          <HorizontalSlider products={relatedProducts} />
        </section>
      )}
      <section className="subscription-section">
        <h2>{t("newsArticlePage.subscribeToLatestResearch")}</h2>
        <SubscriptionForm />
      </section>

      {relatedArticles.length > 0 && (
        <section className="related-articles-section">
          <h2>{t("newsArticlePage.relatedArticles")}</h2>
          <div className="related-articles-grid">
            {relatedArticles.map((relatedArticle) => (
              <div key={relatedArticle.id} className="related-article-card">
                <a href={`/news/${relatedArticle.id}`}>
                  <img
                    src={relatedArticle.image}
                    alt={relatedArticle.title}
                    className="related-article-image"
                  />
                  <h3>{relatedArticle.title}</h3>
                </a>
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};


export default NewsArticlePage;
