import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import KidsPage from "./pages/KidsPage";
import TeamsPage from "./pages/TeamsPage";
import NewsPage from "./pages/NewsPage";
import MenPage from "./pages/MenPage";
import WomenPage from "./pages/WomenPage";
import SearchResults from "./components/SearchResults";
import NewsArticlePage from "./pages/NewsArticlePage";
import CategoryPage from "./pages/CategoryPage";
import CookieConsent from "./components/CookieConsent";
import { Analytics } from "@vercel/analytics/react";
import EditArticlePage from "./pages/EditArticlePage";
import Login from "./components/Login"; // Import the login page
import UserArticlesPage from "./pages/UserArticlesPage"; // Import UserArticlesPage

import { useAuth, AuthProvider } from "./components/AuthProvider"; // Import Auth context

// Protected route component
const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  
  if (!currentUser) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }
  
  // If the user is authenticated, render the requested page
  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/kids" element={<KidsPage />} />
          <Route path="/teams" element={<TeamsPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/men" element={<MenPage />} />
          <Route path="/women" element={<WomenPage />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/news/:id" element={<NewsArticlePage />} />
          <Route path="/category/:categoryName" element={<CategoryPage />} />
          
         {/* Protect the user articles route */}
         <Route
            path="/my-articles"
            element={
              <ProtectedRoute>
                <UserArticlesPage />
              </ProtectedRoute>
            }
          />

          {/* Protected route for editing articles */}
          <Route 
            path="/articles/edit/:id" 
            element={
              <ProtectedRoute>
                <EditArticlePage />
              </ProtectedRoute>
            } 
          />

          {/* Route for creating a new article */}
          <Route
            path="/articles/create"
            element={
              <ProtectedRoute>
                <EditArticlePage />
              </ProtectedRoute>
            }
          />


          {/* Login route */}
          <Route path="/login" element={<Login />} />
        </Routes>
        <CookieConsent />
        <Analytics />
      </Router>
    </AuthProvider>
  );
}

export default App;
