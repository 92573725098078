import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "./authService"; // Your login logic
import { useAuth } from "./AuthProvider"; // Access the auth context
import { useTranslation } from "react-i18next"; // Import translation hook
import "../styles/Login.css";

const Login = () => {
  const { t } = useTranslation(); // Initialize translation
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setCurrentUser } = useAuth(); // Update the current user in the context
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const user = await loginUser(email, password);
      setCurrentUser(user); // Set the user in the AuthProvider context
      navigate("/"); // Redirect to the home page or another protected route
    } catch (error) {
      setError(t("login.error")); // Set an error message in case of login failure
    }
  };

  return (
    <div className="login-container">
      <h2>{t("login.title")}</h2>
      {error && <p className="error-message">{error}</p>} {/* Display error message */}
      <input
        type="email"
        placeholder={t("login.emailPlaceholder")}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder={t("login.passwordPlaceholder")}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>{t("login.loginButton")}</button>
    </div>
  );
};

export default Login;
