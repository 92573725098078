import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/HeroBanner.css";
import { getUserLanguage } from "../utils";

import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Ensure this path is correct in your project

const HeroBanner = () => {
  const { t } = useTranslation(); // Initialize translation
  const [products, setProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchProducts = async () => {
      const userLanguage = getUserLanguage();
      try {
        // Query the 'products' collection where 'language' matches the user's language
        const productsQuery = query(
          collection(db, "products"),
          where("language", "==", userLanguage)
        );
        const querySnapshot = await getDocs(productsQuery);

        const productsData = [];

        querySnapshot.forEach((doc) => {
          productsData.push({ id: doc.id, ...doc.data() });
        });

        // Create a map to store the first product of each category
        const categoryMap = {};

        productsData.forEach((product) => {
          product.categories.forEach((category) => {
            if (!categoryMap[category]) {
              categoryMap[category] = product;
            }
          });
        });

        // Extract the first product from each category
        const firstProducts = Object.values(categoryMap);
        setProducts(firstProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    // Automatically move to the next slide every 5 seconds
    if (products.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [products]);

  const handlePrevClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + products.length) % products.length
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  if (products.length === 0) {
    return <div>{t("loading")}</div>; // Internationalized loading message
  }

  const currentProduct = products[currentIndex];

  return (
    <section className="hero-slider">
      <div
        className="slide"
        style={{ backgroundImage: `url(${currentProduct.image})` }}
      >
        <div className="slide-content">
          <h1>{currentProduct.name}</h1>
          <p>{t("heroBanner.exclusiveGear")}</p>{" "}
          {/* Internationalized slogan */}
          <a
            href={currentProduct.affiliateLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="shop-now">{t("heroBanner.shopNow")}</button>{" "}
            {/* Internationalized button */}
          </a>
        </div>
      </div>
      <div className="slider-controls">
        <button className="prev" onClick={handlePrevClick}>
          &#10094;
        </button>
        <button className="next" onClick={handleNextClick}>
          &#10095;
        </button>
      </div>
      <div className="slider-dots">
        {products.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default HeroBanner;
