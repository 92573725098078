import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaBars, FaTimes } from "react-icons/fa";
import SearchBar from "./SearchBar"; // Ensure the correct path
import "../styles/Header.css";
import { getUserLanguage } from "../utils";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Ensure this path is correct in your project
import { useAuth } from "./AuthProvider"; // Import the auth context

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [products, setProducts] = useState([]);
  const { t } = useTranslation();
  const { currentUser, setCurrentUser, logoutUser } = useAuth(); // Access current user and setCurrentUser
  const navigate = useNavigate();

  
  useEffect(() => {
    const userLanguage = getUserLanguage();

    const fetchProducts = async () => {
      try {
        const productsQuery = query(
          collection(db, "products"),
          where("language", "==", userLanguage)
        );
        const querySnapshot = await getDocs(productsQuery);
        const productsData = [];
        querySnapshot.forEach((doc) => {
          productsData.push({ id: doc.id, ...doc.data() });
        });
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();

    
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobile(false); // Reset mobile menu on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  const handleLinkClick = () => {
    if (isMobile) {
      setIsMobile(false); // Close the mobile menu only if it's open
    }
  };

  const handleLogout = async () => {
    await logoutUser(); // Log the user out
    navigate("/login"); // Redirect to the login page
  };


  return (
    <header className="header">
      <nav className="navbar">
        <div className="menu-icon" onClick={toggleMobileMenu}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
        <h1 className="logo">
          <Link to="/">YouSmart</Link>
        </h1>

        <ul
          className={
            isMobile ? "nav-links nav-links-mobile active" : "nav-links"
          }
        >
          <li>
            <Link to="/" onClick={handleLinkClick}>
              {t("header.home")}
            </Link>
          </li>

          <li>
            <Link to="/news" onClick={handleLinkClick}>
              {t("header.news")}
            </Link>
          </li>

          {/* Conditionally show Login or Logout depending on the user's authentication status */}
          {currentUser ? (
            <>
              <li>
                <Link to="/my-articles" onClick={() => setIsMobile(false)}>
                  {t("header.myArticles")}
                </Link>
              </li>
              <li>
                <span>{currentUser.email}</span> {/* Display user email */}
              </li>
              <li>
                <button onClick={handleLogout}>{t("header.logout")}</button>
              </li>
            </>
          ) : (
            <li>
              <Link to="/login" onClick={handleLinkClick}>
                {t("header.login")}
              </Link>
            </li>
          )}
        </ul>

        {/* Reuse the enhanced SearchBar component */}
        <SearchBar products={products} />
      </nav>
    </header>
  );
};

export default Header;
