import { auth, db } from "../firebase"; // Firebase config (auth and Firestore)
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";

// Function to register a new user
export const registerUser = async (email, password, role) => {
  try {
    // Create a user in Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Store additional user info in Firestore
    await setDoc(doc(db, "users", user.uid), {
      email: user.email,
      role: role, // Role: Admin, Author, Reader
      articles: [] // Initialize articles array
    });

    return user; // Return the created user
  } catch (error) {
    console.error("Error registering user:", error);
    throw error; // Throw error to be handled in the UI
  }
};

// Function to log in an existing user
export const loginUser = async (email, password) => {
  try {
    // Log the user in via Firebase Authentication
    if (!auth) {
      throw new Error("Firebase auth service not initialized");
    }
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Fetch additional user info from Firestore
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists()) {
      return { uid: user.uid, ...userDoc.data() }; // Return user data
    } else {
      throw new Error("No such user in Firestore");
    }
  } catch (error) {
    console.error("Error logging in:", error);
    throw error; // Throw error to be handled in the UI
  }
};
