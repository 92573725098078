import React, { useEffect, useState } from "react";
import { useAuth } from "../components/AuthProvider"; // Import the authentication context
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Import Firestore config
import { useTranslation } from "react-i18next"; // Import translation hook
import "../styles/UserArticlesPage.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";

const UserArticlesPage = () => {
  const { currentUser } = useAuth(); // Access the logged-in user's data
  const { t } = useTranslation(); // Initialize the translation hook
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserArticles = async () => {
      if (currentUser) {
        try {
          const articlesRef = collection(db, "news");
          const q = query(articlesRef, where("authorId", "==", currentUser.uid));
          const querySnapshot = await getDocs(q);

          const userArticles = [];
          querySnapshot.forEach((doc) => {
            userArticles.push({ id: doc.id, ...doc.data() });
          });

          setArticles(userArticles);
        } catch (error) {
          console.error("Error fetching articles:", error);
          setError(t("userArticles.fetchError")); // Show error if fetching fails
        }
        setLoading(false);
      }
    };

    fetchUserArticles();
  }, [currentUser, t]);

  if (loading) {
    return <div>{t("userArticles.loading")}</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="user-articles-container">
      <h1>{t("userArticles.welcome", { name: currentUser?.firstName })}</h1>
      <h2>{t("userArticles.yourArticles")}</h2>
    {/* Link to create a new article */}
        <Link to="/articles/create" className="create-article-button">
        {t("userArticles.createButton")}
      </Link>

      {articles.length === 0 ? (
        <p className="no-articles">{t("userArticles.noArticles")}</p>
      ) : (
        <ul className="articles-list">
          {articles.map((article) => (
            <li key={article.id} className="article-item">
              <h3 className="article-title"> 
                <a
                    href={`/news/${article.id}`}
                    
                >{article.title} </a></h3>
              <p className="article-snippet">{article.content.substring(0, 100)}...</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UserArticlesPage;
